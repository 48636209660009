import { useConvaiClient } from "./hooks/useConvaiClient";
import ChatBubble from "./components/chat/Chat";

function App() {
	const { client } = useConvaiClient(
		"fe528f14-8468-11ef-bb5a-42010a7be011",
		"2877b9f8ff76c95f8ff2ead333761e82",
	);

	return (
		<div className="box-iframe">
			<iframe
				className="iframe"
				src="https://www.spatial.io/embed/Shjpolice-658bcc2c60c4e4c38b3d866e?share=9004947785570802184"
				width={"100%"}
				height={"100%"}
				style={{
					border: "0",
				}}
			/>
			{client ? <ChatBubble chatHistory={"Show"} client={client} /> : null}
		</div>
	);
}

export default App;
